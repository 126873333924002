<template>
  <v-card width="90%" style="margin: 15px auto;" v-if="card">
    <CommentDialog
      v-if="commentDialogShow"
      :title="commentDialogTitle"
      :fieldName="commentDialogFieldName"
      :card="card"
      :lastComment="commentDialogLastComment"
      v-on:create:comment="createComment"
      v-on:update:comment="updateComment"
      v-on:delete:comment="deleteComment"
      v-on:close="commentDialogShow = false"
      v-on:commentUpdate="highligthFields"
    />
    <slot name="title" :card="card"/>
    <v-card-text>
      <component
        class="hovered"
        v-if="card"
        :is="moduleCardView"
        :cardId="card.moduleCardId"
        v-on:click="openCommentDialog"
        v-on:update:cardLoaded="highligthFields()"
      />
    </v-card-text>
    <slot name="actions" :card="card"/>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CommentDialog from './CommentDialog.vue'
//TMPLS
import SimpleCardTmpl from '@/modules/simpleCard/views/components/ViewTmpl.vue'
import SpecialCardTmpl from '@/modules/specialCard/views/components/ViewTmpl.vue'

export default {
  components: {
    CommentDialog,
    SimpleCardTmpl,
    SpecialCardTmpl,
  },
  data: () => ({
    card: null,
    // moduleCard: null,
    moduleCardView: null,
    // cardNewComments: [],
    commentDialogShow: false,
    commentDialogTitle: '',
    commentDialogFieldName: '',
    commentDialogLastComment: null,
    // cardViewKey: 1,
  }),
  computed: {
    ...mapState('user', { userId: state => state.profile.userId }),
  },
  methods: {
    createComment(comment) {
      this.card.comments.push(Object.assign({}, comment))
      this.highligthFields()
      this.commentDialogShow = false
    },
    updateComment(comment) {
      let com = this.card.comments.find(c => {
        return c.id === comment.id
      })
      if (com) {
        com.message = comment.message
      } else {
        alert('Fatal error, please reload page')
      }
      this.highligthFields()
      this.commentDialogShow = false
    },
    deleteComment(comment) {
      alert('delete not work')
      this.highligthFields()
      this.commentDialogShow = false
    },
    sortCardComments() {
      this.card.comments.sort((a, b) => {
        if (a.fieldName == b.fieldName) {
          return b.createdAt - a.createdAt
        } else if (a.fieldName > b.fieldName) {
          return 1
        }
        return -1
      })
    },
    highligthFields() {
      this.sortCardComments()
      var fn = ''
      this.card.comments.forEach(c => {
        if (c.fieldName != fn) {
          fn = c.fieldName
          let el = document.getElementById(fn)
          if (el) {
            switch(c.status) {
              case 1:
                el.style.backgroundColor = 'red'
                break
              case 2:
                el.style.backgroundColor = 'green'
                break
              case 3:
                el.style.backgroundColor = 'orange'
                break
              case 4:
                el.style.backgroundColor = 'blue'
                break
            }
          } else {
            console.log(fn,'not found in DOM')
          }
        }
      })
    },
    openCommentDialog(cardField) {
      if(this.card.status == 3 || this.card.status == 8) {
        // card in correct
        if (!this.card.comments.find(c => c.fieldName == cardField.fieldName)) {
          return
        }
      }
      this.commentDialogTitle = cardField.fieldTitle
      this.commentDialogFieldName = cardField.fieldName
      this.commentDialogLastComment = this.card.comments.find(c => {
        return (c.fieldName == cardField.fieldName && c.status === 4)
      })
      if (this.commentDialogLastComment == null) {
        this.commentDialogLastComment = { message: '' }
      }
      this.commentDialogShow = true
    },
    getCard() {
      this.$axios.get('/manufacture/card/view', {
        params: { id: this.$route.query.id }
      }).then(r => {
        let tmpl = r.data.module.name + 'Tmpl'
        this.moduleCardView = tmpl.charAt(0).toUpperCase() + tmpl.slice(1)
        this.card = r.data
        // this.$nextTick(() => {this.highligthFields()})
      }).catch(() => {})
    }
  },
  mounted: function() {
    this.getCard()
  }
}
</script>

<style scoped>
  .hovered >>> .hovered {
    background-color: #cacaca;
    opacity: 90%;
  }
  .hovered:hover >>> .hovered:hover {
    opacity: 65%;
  }
</style>
