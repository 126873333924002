<template>
  <v-container v-if="card">
    <table class="table text-center">
      <tr>
        <th style="width: 25%;">СОГЛАСОВАНО</th>
        <th
          class="hovered"
          id="name"
          @click="$emit('click', { fieldName: 'name', fieldTitle: 'Название карты'})"
          >
            Технологическая карта сварки №{{card.version.name}}
        </th>
        <th style="width: 25%;">УТВЕРЖДАЮ</th>
      </tr>
      <tr>
        <td>{{card.version.agreeder.post}}</td>
        <th>ОПИСАНИЕ КАРТЫ</th>
        <td>{{card.version.accepter.post}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.org}}</td>
        <td
          class="hovered"
          id="about"
          rowspan="3"
          @click="$emit('click', { fieldName: 'about', fieldTitle: 'Орписание карты'})"
          >
          {{card.version.about}}
        </td>
        <td>{{card.version.accepter.org}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.name}}</td>
        <td>{{card.version.accepter.name}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.date}}</td>
        <td>{{card.version.accepter.date}}</td>
      </tr>
    </table>
    <table class="table text-center">
      <tr><th colspan="5">Характеристика соединения</th></tr>
      <tr>
        <th>Тип соединения</th>
        <th>Норм. документ</th>
        <th>Тип шва</th>
        <th>Вид соединения</th>
        <th>Положение стыка</th>
      </tr>
      <tr>
        <td
          class="hovered"
          id="jointType"
          @click="$emit('click', { fieldName: 'jointType', fieldTitle: 'Тип соединения'})"
          >
          {{card.version.jointType}}
        </td>
        <td
          class="hovered"
          id="normDoc"
          @click="$emit('click', { fieldName: 'normDoc', fieldTitle: 'Нормативный документ'})"
          >
          {{card.version.normDoc}}
        </td>
        <td
          class="hovered"
          id="seamType"
          @click="$emit('click', { fieldName: 'seamType', fieldTitle: 'Тип шва'})"
          >
          {{card.version.seamType}}
        </td>
        <td
          class="hovered"
          id="seamKind"
          @click="$emit('click', { fieldName: 'seamKind', fieldTitle: 'Вид шва'})"
          >
          {{card.version.seamKind}}
        </td>
        <td
          class="hovered"
          id="weldPosition"
          @click="$emit('click', { fieldName: 'weldPosition', fieldTitle: 'Положение при сварки'})"
          >
          {{card.version.weldPosition}}
        </td>
      </tr>
    </table>
    <table class="table text-center">
      <tr><th colspan="4">Характеристика свариваемых элементов</th></tr>
      <tr>
        <th>Тип элемента</th>
        <th>Марка материала</th>
        <th>Диаметр</th>
        <th>Толщина</th>
      </tr>
      <tr
        class="hovered"
        v-for="el in card.version.elements"
        :id="'element' + el.num"
        @click="$emit('click', { fieldName: 'element' + el.num, fieldTitle: 'Элемент сварки ' + el.num})"
        >
        <td>{{el.type}}</td>
        <td>{{el.material}}</td>
        <td>{{el.d || '- - -'}}</td>
        <td>{{el.s || '- - -'}}</td>
      </tr>
    </table>
    <table class="table text-center">
      <tr>
        <th style="width: 50%;">Конструкция соединения</th>
        <th style="width: 50%;">Конструктивные элементы шва</th>
      </tr>
      <tr
        class="hovered"
        id="jointConstruct"
        @click="$emit('click', { fieldName: 'jointConstruct', fieldTitle: 'Параметры соединения'})"
        >
        <td colspan="2">
          <table class="table">
            <tr>
              <td style="width: 50%;">
                <img :src="card.version.jointConstruct.jointUrl" style="max-width: 100%;"/>
              </td>
              <td style="width: 50%;">
                <img :src="card.version.jointConstruct.seamUrl" style="max-width: 100%;"/>
              </td>
            </tr>
            <tr>
              <td>{{card.version.jointConstruct.jointText}}</td>
              <td>{{card.version.jointConstruct.seamText}}</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table text-center">
      <tr><th>Порядок сварки</th></tr>
      <tr
        class="hovered"
        id="weldOrder"
        @click="$emit('click', { fieldName: 'weldOrder', fieldTitle: 'Порядок сварки'})"
        >
        <td>
          <v-container>
            <v-row justify="center">
              <v-col cols="4" v-for="(wo, i) in card.version.weldOrder" :key="'WO_' + i">
                <table class="table">
                  <tr><td ><img :src="wo.url" style="max-width: 100%;"/></td></tr>
                  <tr><td>{{wo.text}}</td></tr>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </tr>
      <tr><th colspan="2">Комментарий к порядку сварки</th></tr>
      <tr>
        <td
          class="hovered"
          colspan="2"
          id="weldOrderCommit"
          @click="$emit('click', { fieldName: 'weldOrderCommit', fieldTitle: 'Коментрий к порядку сварки'})"
          >
          {{card.version.weldOrderCommit}}
        </td>
      </tr>
    </table>
    <table class="table">
      <tr>
        <th colspan="8">Режимы сварки</th>
      </tr>
      <tr>
        <th rowspan="2">Слой шва</th>
        <th rowspan="2">Марка сварочного материала</th>
        <th rowspan="2">Диаметр электрода, мм</th>
        <th rowspan="2">Полярность</th>
        <th colspan="2">Сварочный ток, А</th>
        <th colspan="2">Напряжение, В</th>
      </tr>
      <tr>
        <th>Минимум</th>
        <th>Максимум</th>
        <th>Минимум</th>
        <th>Максимум</th>
      </tr>
      <tr
        :id="'modes' + l.num"
        class="table text-center hovered"
        @click="$emit('click', { fieldName: 'modes' + l.num, fieldTitle: 'Режимы сварки'})"
        v-for="l in card.version.layers"
        >
        <td>{{getLayerNameById(l.nameId)}}</td>
        <td>{{l.weldMaterial}}</td>
        <td>{{l.electrode}}</td>
        <td>{{getPolarityNameById(l.polarityNameId)}}</td>
        <td>{{getCurrent(l.channels).min}}</td>
        <td>{{getCurrent(l.channels).max}}</td>
        <td>{{getVoltage(l.channels).min}}</td>
        <td>{{getVoltage(l.channels).max}}</td>
      </tr>
    </table>
    <table class="table">
      <tr><th>Дополнительные параметры</th></tr>
      <tr>
        <td
          class="hovered"
          id="others"
          @click="$emit('click', { fieldName: 'others', fieldTitle: 'Дополнительные параметры'})"
          >
          {{card.version.others}}
        </td>
      </tr>
      <tr><th>Преварительный подогрев</th></tr>
      <tr>
        <td
          class="hovered"
          id="preHeat"
          @click="$emit('click', { fieldName: 'preHeat', fieldTitle: 'Преварительный подогрев'})"
          >
          {{card.version.preHeat}}
        </td>
      </tr>
      <tr><th>Требования к кромкам</th></tr>
      <tr>
        <td
          class="hovered"
          id="edgeRequire"
          @click="$emit('click', { fieldName: 'edgeRequire', fieldTitle: 'Требования к кромкам'})"
          >
          {{card.version.edgeRequire}}
        </td>
      </tr>
      <tr><th>Требования к сборке</th></tr>
      <tr>
        <td
          class="hovered"
          id="assemblyRequire"
          @click="$emit('click', { fieldName: 'assemblyRequire', fieldTitle: 'Требования к сборке'})"
          >
          {{card.version.assemblyRequire}}
        </td>
      </tr>
      <tr><th>Требования к прихваткам</th></tr>
      <tr>
        <td
          class="hovered"
          id="tackweldRequire"
          @click="$emit('click', { fieldName: 'tackweldRequire', fieldTitle: 'Требования к прихваткам'})"
          >
          {{card.version.tackweldRequire}}
        </td>
      </tr>
      <tr><th>Требования к сварке</th></tr>
      <tr>
        <td
          class="hovered"
          id="weldRequire"
          @click="$emit('click', { fieldName: 'weldRequire', fieldTitle: 'Требования к сварке'})"
          >
          {{card.version.weldRequire}}
        </td>
      </tr>
      <tr><th>Сварочное оборудование</th></tr>
      <tr>
        <td
          class="hovered"
          id="weldEquipment"
          @click="$emit('click', { fieldName: 'weldEquipment', fieldTitle: 'Сварочное оборудование'})"
          >
          <span v-for="eq in card.version.weldEquipment">{{eq.name}}; </span>
        </td>
      </tr>
      <tr><th>Послесварочная термическая обработка</th></tr>
      <tr>
        <td
          class="hovered"
          id="postHeat"
          @click="$emit('click', { fieldName: 'postHeat', fieldTitle: 'Послесварочная термическая обработка'})"
          >
          Тип обработки: {{card.version.postHeatType}};
          Оборудование: <span v-for="eq in card.version.postHeatEquipment">{{eq.name}}, </span>;
          Охлаждение: {{card.version.postHeatCooling}};
          Циклы обработки: <span v-for="c in card.version.postHeatCycles">температура: {{c.temperature}}, выдержка: {{c.delay}}</span>;
        </td>
      </tr>
      <tr><th>Операционный контроль</th></tr>
      <tr>
        <td
          class="hovered"
          id="operationControl"
          @click="$emit('click', { fieldName: 'operationControl', fieldTitle: 'Операционный контроль'})"
          >
          {{card.version.operationControl}}
        </td>
      </tr>
      <tr><th>Техника безопасности и охрана труда</th></tr>
      <tr>
        <td
          class="hovered"
          id="safety"
          @click="$emit('click', { fieldName: 'safety', fieldTitle: 'Техника безопасности и охрана труда'})"
          >
          {{card.version.safety}}
        </td>
      </tr>
    </table>
    <table class="table" style="max-width: 50%">
      <tr>
        <th class="pr-2 text-right">Разработал</th>
        <td class="pl-2 text-left">{{developer}}</td>
      </tr>
      <tr>
        <th class="pr-2 text-right">Дата</th>
        <td class="pl-2 text-left">{{secToDatetime(this.card.createdAt)}}</td>
      </tr>
    </table>
    <table class="table" v-if="card.version.files.length">
      <thead>
        <tr>
          <th class="th text-center">
            Прикрепленные файлы
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="f in card.version.files">
          <td>
            <a :href="f.url" :download="f.name" target="_blank">
              {{f.name}}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </v-container>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'
import { mapGetters } from 'vuex'

export default {
  props: ['cardId'],
  data() {
    return {
      card: null,
      secToDatetime,
    }
  },
  computed: {
    ...mapGetters(['getLayerNameById', 'getPolarityNameById']),
    developer() {
      return `${this.card.developer.firstname} ${this.card.developer.middlename} ${this.card.developer.lastname}`
    },
  },
  methods: {
    getCurrent(channels) {
      let c = channels.find(ch => ch.nameId == 1)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    },
    getVoltage(channels) {
      let c = channels.find(ch => ch.nameId == 2)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    }
  },
  mounted: function() {
    this.$axios.get('/special-card/card/view', {
      params: { id: this.cardId }
    }).then(r => {
      this.card = r.data
      this.$nextTick(() => { this.$emit('update:cardLoaded') })
    }).catch(() => {})
  },
}
</script>

<style>
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  .table td {
    height: 22px;
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
  }
  .table th {
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    text-align: center;
  }
</style>
