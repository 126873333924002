<template>
  <table class="table">
    <thead>
      <tr>
        <td>
          Было
        </td>
        <td>
          Стало
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{oldValue}}</td>
        <td>{{newValue}}</td>
      </tr>
      <tr>
        <td colspan="2">Коментарий</td>
      </tr>
      <tr>
        <td colspan="2">{{message}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['oldValue', 'newValue', 'message']
}
</script>

<style>
.table {
  border-collapse: collapse;
  border: 1px solid grey;
  width: 100%;
}

.table td {
  border: 1px solid grey;
  text-align: center;
  border-bottom: 0px;
}
</style>
