<template>
  <v-container v-if="card">
    <table class="table text-center">
      <tr>
        <th style="width: 25%;">СОГЛАСОВАНО</th>
        <th
          class="hovered"
          id="name"
          @click="$emit('click', { fieldName: 'name', fieldTitle: 'Название карты'})"
          >
            Технологическая карта сварки №{{card.version.name}}
        </th>
        <th style="width: 25%;">УТВЕРЖДАЮ</th>
      </tr>
      <tr>
        <td>{{card.version.agreeder.post}}</td>
        <th>ОПИСАНИЕ КАРТЫ</th>
        <td>{{card.version.accepter.post}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.org}}</td>
        <td
          class="hovered"
          id="about"
          rowspan="3"
          @click="$emit('click', { fieldName: 'about', fieldTitle: 'Описание карты'})"
          >
          {{card.version.about}}
        </td>
        <td>{{card.version.accepter.org}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.name}}</td>
        <td>{{card.version.accepter.name}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.date}}</td>
        <td>{{card.version.accepter.date}}</td>
      </tr>
    </table>
    <table class="table">
      <tr><th colspan="5" class="th">Харктеристика соединения</th></tr>
      <tr>
        <th class="th">Способ сварки</th>
        <th class="th">Вид соединяемых элементов</th>
        <th class="th">Тип шва</th>
        <th class="th">Тип соединения</th>
        <th class="th">Положение стыка</th>
      </tr>
      <tr>
        <td
          class="th hovered"
          id="weldMethod"
          @click="$emit('click', { fieldName: 'weldMethod', fieldTitle: 'Способ сварки'})"
          >
          {{card.version.weldMethod}}
        </td>
        <td
          class="th hovered"
          id="elementsType"
          @click="$emit('click', { fieldName: 'elementsType', fieldTitle: 'Вид элементов'})"
          >
          {{card.version.elementsType}}
        </td>
        <td
          class="th hovered"
          id="seamType"
          @click="$emit('click', { fieldName: 'seamType', fieldTitle: 'Тип шва'})"
          >
          {{card.version.seamType}}
        </td>
        <td
          class="th hovered"
          id="jointType"
          @click="$emit('click', { fieldName: 'jointType', fieldTitle: 'Тип соединения'})"
          >
          {{card.version.jointType}}
        </td>
        <td
          class="th hovered"
          id="weldPosition"
          @click="$emit('click', { fieldName: 'weldPosition', fieldTitle: 'Положение'})"
          >
          {{card.version.weldPosition}}
        </td>
      </tr>
    </table>
    <table class="table">
      <tr><th colspan="4" class="th">Характеристика свариваемых элементов</th></tr>
      <tr>
        <th class="th"></th>
        <th class="th">Марка материала</th>
        <th class="th">Диамтер, (мм)</th>
        <th class="th">Толщина, (мм)</th>
      </tr>
      <tr
        class="th hovered"
        id="element1"
        @click="$emit('click', { fieldName: 'element1', fieldTitle: 'Элемент 1'})"
        >
        <td class="th">1</td>
        <td>{{card.version.elem1Material}}</td>
        <td>{{card.version.d1 || '---' }}</td>
        <td>{{card.version.s1 || '---'}}</td>
      </tr>
      <tr
        class="th hovered"
        id="element2"
        @click="$emit('click', { fieldName: 'element2', fieldTitle: 'Элемент 2'})"
        >
        <td class="th">2</td>
        <td class="th">{{card.version.elem2Material}}</td>
        <td class="th">{{card.version.d2 || '---'}}</td>
        <td class="th">{{card.version.s2 || '---'}}</td>
      </tr>
    </table>
    <table class="table">
      <thead>
        <tr>
          <th colspan="7" class="th" style="background-color: white;">
            Режимы сварки и сварочные материалы
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="th" rowspan="2">Слой шва</th>
          <th class="th" rowspan="2">Диаметр электрода, мм</th>
          <th class="th" rowspan="2">Полярность</th>
          <th class="th" colspan="2">Сварочный ток, А</th>
          <th class="th" colspan="2">Напряжение, В</th>
        </tr>
        <tr>
          <th class="th">Минимум</th>
          <th class="th">Максимум</th>
          <th class="th">Минимум</th>
          <th class="th">Максимум</th>
        </tr>
        <tr
          class="hovered"
          :id="'modes' + l.num"
          @click="$emit('click', { fieldName: 'modes' + l.num, fieldTitle: 'Режимы сварки'})"
          v-for="l in card.version.layers"
          >
          <td class="th">{{getLayerNameById(l.layerNameId)}}</td>
          <td class="th">{{l.electrode}}</td>
          <td class="th">{{getPolarityNameById(l.polarityNameId)}}</td>
          <td class="th">{{getCurrent(l.channels).min}}</td>
          <td class="th">{{getCurrent(l.channels).max}}</td>
          <td class="th">{{getVoltage(l.channels).min}}</td>
          <td class="th">{{getVoltage(l.channels).max}}</td>
        </tr>
      </tbody>
    </table>
    <table class="table" style="max-width: 50%;">
      <thead>
        <tr>
          <th class="th text-right pr-1">
            Разработал
          </th>
          <th class="th text-left pl-1">
            {{
              card.developer.firstname + ' ' +
              card.developer.middlename + ' ' +
              card.developer.lastname
            }}
          </th>
        </tr>
        <tr>
          <th class="th text-right pr-1">
            Дата разработки
          </th>
          <th class="th text-left pl-1">
            {{secToDatetime(card.createdAt)}}
          </th>
        </tr>
      </thead>
    </table>
    <table class="table" v-if="card.version.files.length">
      <thead>
        <tr>
          <th class="th text-center">
            Прикрепленные файлы
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="f in card.version.files">
          <td>
            <a :href="f.url" :download="f.name" target="_blank">
              {{f.name}}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { secToDatetime } from '@/libs/time.js'

export default {
  props: ['cardId'],
  data: () => ({
    card: null,
    secToDatetime,
  }),
  computed: {
    ...mapGetters(['getLayerNameById', 'getPolarityNameById']),
    developer() {
      return `${this.card.developer.firstname} ${this.card.developer.middlename} ${this.card.developer.lastname}`
    },
  },
  methods: {
    getCurrent(channels) {
      let c = channels.find(ch => Number(ch.channelId) === 1)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    },
    getVoltage(channels) {
      let c = channels.find(ch => Number(ch.channelId) === 2)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    }
  },
  mounted: function() {
    this.$axios.get('/simple-card/card/view', {
      params: { id: this.cardId }
    }).then(r => {
      this.card = r.data
      this.$nextTick(() => { this.$emit('update:cardLoaded') })
    }).catch(() => {})
  },
}
</script>

<style>
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  .table td {
    height: 22px;
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
  }
  .table th {
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    text-align: center;
  }
</style>
