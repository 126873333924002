import Store from '@/store'

export function toPercentString(a, b) {
  if (Number(b) === 0) return '0.0%'
  return ((Number(a) / Number(b)) * 100).toFixed(1) + '%'
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
