
export function secToDatetime(sec) {
	if(!sec) return null
    let d = new Date(sec * 1000)
    return ('0' + d.getUTCDate()).slice(-2) + '.' + 
          ('0' + (d.getUTCMonth() + 1)).slice(-2) + '.' + 
          d.getUTCFullYear() + ' ' + 
          ('0' + d.getUTCHours()).slice(-2) + ':' + 
          ('0' + d.getUTCMinutes()).slice(-2) + ':' + 
          ('0' + d.getUTCSeconds()).slice(-2)
}

export function dateToSec(date) {
	return date ? parseInt((new Date(date).getTime() / 1000).toFixed(0)) : null
}

export function secToDate(sec) {
	if(!sec) return null
  let d = new Date(sec * 1000)
  return ('0' + d.getUTCDate()).slice(-2) + '.' + 
	 ('0' + (d.getUTCMonth() + 1)).slice(-2) + '.' + 
	 d.getUTCFullYear()
}

export function secToTime(sec) {
  if(!sec) return null
  let d =  new Date(sec * 1000)
  return ('0' + d.getUTCHours()).slice(-2) + ':' +
         ('0' + d.getUTCMinutes()).slice(-2) + ':' +
         ('0' + d.getUTCSeconds()).slice(-2)
}

export function secToHoursTime(sec) {
  if(!sec) return null
  let d =  new Date(sec * 1000)
  let res = ''
  if (d.getUTCHours() > 0) res += ('0' + d.getUTCHours()).slice(-2) + 'ч.'
  if (d.getUTCMinutes() > 0) res += ('0' + d.getUTCMinutes()).slice(-2) + 'м.'
  res += ('0' + d.getUTCSeconds()).slice(-2) + 'с.'
  return  res
}
